const createQuery = () => `
  ... on ArticleListWidget {
    title
    dedupe
    limit
    lists
    articles {
      _id
      fullUrlPath
      displayDate
      sell
      headline
    }
  }
`
export default createQuery
