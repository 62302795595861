const createQuery = () => `
... on UpcomingReleaseWidget {
  release {
    title
    classification
    releaseDate
    shortFormInsight
    synopsis
    slug
    images {
      _573 {
        url
      }
    }
  }
}
`
export default createQuery
