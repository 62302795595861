import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on FeaturedArticleWidget {
    dedupe
    title
    lists
    slanted
    articles {
      _id
      headline
      shortHeadline
      fullUrlPath
      sell
      images {
        hero_5x4_1000 {
          ${imageProperties}
        }
        hero_5x4_500 {
          ${imageProperties}
        }
        hero_16x9_550 {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
