const createQuery = () => `
... on ContactsWidget {
  title
  contacts {
    name
    job
    phone
    secondaryPhone
    email
  }
}
`
export default createQuery
