const createQuery = () => `
  ... on LogoCarouselWidget {
    title
    images {
      title
      link
      images {
        logo_5x3_500 {
          url
        }
      }
    }
  }
`
export default createQuery
