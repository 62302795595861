import imageProperties from '../lib/image-properties'

const createQuery = () => `
... on PaginatedArticleListWidget {
  id
  heroFirstItem
  showImages
  articles(page: $page) {
    headline
    sell
    category
    fullUrlPath
    displayDate
    images {
      hero_16x9_1026 {
        ${imageProperties}
      }
      thumbnail_1x1_225 {
        ${imageProperties}
      }
    }
  }
  totalArticles
}
`
export default createQuery
